const Analytics = await import("../../utils/Analytics").then(
  (module) => new module.default()
);
class HelperBlock {
  self;
  analytics = Analytics;
  actionLink = ".cmp-teaser__action-link";
  textLink = ".cmp-teaser__description p a";
  subcontentLink = ".cmp-teaser__subcontent__description p a";
  imageLink = ".cmp-teaser__image";
  constructor(helperBlock) {
    this.self = helperBlock;
    $(this.self).find(this.actionLink).each((index, item) => {
      $(item).on("click", (event) => this.onClick(event));
    });
    $(this.self).find(this.textLink).each((index, item) => {
      $(item).on("click", (event) => this.textClick(event));
    });
    $(this.self).find(this.subcontentLink).each((index, item) => {
      $(item).on("click", (event) => this.textClick(event));
    });
    $(this.self).find(this.imageLink).each((index, item) => {
      $(item).on("click", (event) => this.imageClick(event));
    });
  }
  onClick(event) {
    let label;
    let action;
    const $current = $(event.currentTarget);
    if ($current.hasClass("js-video-modal")) {
      label = $current.attr("data-yt-id");
      action = $current.attr("aria-label");
    } else {
      label = $current.prop("href");
      action = $current.text();
    }
    this.analytics.sendAnalyticsEventUserAction("cta", action, label);
  }
  textClick(event) {
    const $current = $(event.currentTarget);
    const action = $current.text();
    const label = $current.prop("href");
    this.analytics.sendAnalyticsEventUserAction("text link", action, label);
  }
  imageClick(event) {
    const $current = $(event.currentTarget);
    const label = $current.prop("href");
    this.analytics.sendAnalyticsEventUserAction("cta", "image", label);
  }
}
window.addEventListener("load", (e) => {
  if ($(".helper-block").length) {
    $(".helper-block").each((index, helperBlockHTMLElement) => {
      new HelperBlock(helperBlockHTMLElement);
    });
  }
});
export default HelperBlock;
